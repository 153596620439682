<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/ditui/register/components/InviteCodeField/index.vue
 * @Author: 张兴业
 * @Date: 2021-04-12 10:45:08
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-04-19 14:55:46
-->
<template>
  <div class="phone_field">
    <img
      slot="label"
      src="@/assets/img/ditui/inviteCode.png"
      alt=""
      class="icon left"
    />
    <van-field
      v-model="inviteCode"
      name="inviteCode"
      label-width="48px"
      clearable
      disabled
      :class="inviteCode ? 'phone' : 'phone_un'"
    />
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      inviteCode: this.value
    };
  },
  methods: {
    // phoneChange(value) {
    //   this.$emit("input", value);
    // },
    // clear() {
    //   this.$emit("input", "");
    // }
  }
};
</script>

<style lang="less" scoped>
.phone_field {
  display: flex;
  flex-direction: row;
  // height: 48px;
  border: 1px solid #1ece26;
  border-radius: 13px;
  box-sizing: border-box;

  .icon {
    width: 24px;
    height: 24px;
    margin: auto 12px;
  }

  .phone {
    font-size: 14px;
    font-weight: 500;
    border-radius: 13px;
    padding: 10px 12px 10px 0;
  }

  .phone_un {
    font-size: 12px;
    font-weight: 400;
    border-radius: 13px;
    padding: 10px 12px 10px 0;
  }
}

::v-deep .van-field__control:disabled {
  color: black;
  -webkit-text-fill-color: black;
}
</style>
